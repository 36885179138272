<template>
  <q-page class="q-py-md q-px-xl">
    <div class="row q-gutter-xl">
      <q-card class="my-card">
        <q-card-section class="q-py-xs">
          Data Terhimpun
        </q-card-section>
        <q-card-section class="text-h5 text-center text-bold text-white">
          {{count_survey}}
        </q-card-section>
        <q-card-section class="q-py-xs text-right">
          Dari {{count_siswa - count_karantina}} Orang
        </q-card-section>
      </q-card>
      <!-- <q-card class="my-card">
        <q-card-section class="q-py-xs">
          Sedang Karantina
        </q-card-section>
        <q-card-section class="text-h5 text-center text-bold text-white">
          {{count_karantina}}
        </q-card-section>
        <q-card-section class="q-py-xs text-right">
          Orang
        </q-card-section>
      </q-card> -->
      <q-card class="my-card">
        <q-card-section class="q-py-xs">
          Perlu Karantina
        </q-card-section>
        <q-card-section class="text-h5 text-center text-bold text-white">
          {{need_karantina}}
        </q-card-section>
        <q-card-section class="q-py-xs text-right">
          Orang
        </q-card-section>
      </q-card>
      <q-card class="my-card">
        <q-card-section class="q-py-xs">
          Perlu Isolasi
        </q-card-section>
        <q-card-section class="text-h5 text-center text-bold text-white">
          {{need_isolasi}}
        </q-card-section>
        <q-card-section class="q-py-xs text-right">
          Kamar
        </q-card-section>
      </q-card>
    </div>
    
    <q-dialog v-model="confirm" persistent>
      <q-card class="q-py-md q-px-sm">
        <q-card-section>
          Apakah Anda akan mengarsipkan semua data survey pada periode ini?
        </q-card-section>
        <q-card-section class="row justify-evenly">
          <q-btn dense outline color="indigo-9" style="width: 80px;" no-caps @click="confirm = false" label="Tidak" />
          <q-btn v-close-popup dense  unelevated color="indigo-9" style="width: 80px;" no-caps @click="arsipkan" label="Ya" />
        </q-card-section>
      </q-card>
    </q-dialog>

    <div class="full-width">
      <q-card class="full-width q-my-md">
        <q-card-section horizontal class="row justify-between items-end">
          <q-card-section class="row items-center">
            Kode Unik : <div class="text-h6">{{kode_unik}}</div> 

          </q-card-section>
          
          <q-card-section>
            <q-btn no-caps outline color="indigo-10" label="Arsipkan" @click="confirm = true" />

          </q-card-section>
        </q-card-section>
      </q-card>
      <div class="row justify-between items-end q-py-sm">
        <div class="row">
          Periode : 
          <div v-if="range_date.from" class="text-grey">{{range_date.from | moment('DD MMMM YYYY')}} s.d {{range_date.to | moment('DD MMMM YYYY')}} </div>
          <div v-else class="text-grey text-italic"> -- s.d. -- </div>
        </div>
        <q-input
          outlined
          v-model="search"
          placeholder="Cari Nama"
          dense class="bg-white" 
          style="border-radius: 5px; width: 350px;"  
          @keypress.enter="fetchData('nama')"
        >
          <template v-slot:append>
            <q-btn
              flat
              icon="search"
              class="text-white absolute-right"
              @click="fetchData('nama')"
              style="background-color:#03913F"
            />
          </template>
        </q-input>
      </div>
      <div class="row q-pb-md">
        <q-markup-table bordered dense class="col-12">
          <thead class="text-blue-grey-14">
            <tr>
              <th style="width:10px;" @click="fetchData('no_induk')">No Induk</th>
              <th class="text-left" @click="fetchData('nama')">Nama</th>
              <th class="text-left" @click="fetchData('')">Jabatan</th>
              <th class="text-center" @click="fetchData('jenis_kelamin')">Gender</th>
              <th class="text-center" @click="fetchData('gedung')">Gedung</th>
              <th class="text-center" @click="fetchData('no_kamar')">Kamar</th>
              <th class="text-right" @click="fetchData('nilai_survey')">Nilai Survey</th>
            </tr>
          </thead>
          <tbody v-if="listData.length" class="">
            <tr v-for="d in listData" :key="d.spp_id">
              
              <td class="text-left">{{ d.no_induk }}</td>
              <td class="text-left">{{ d.nama }}</td>
              <td class="text-left">
                {{ d.jabatan }}
              </td>
              <td class="text-center" style="width: 100px;">
                {{ d.jenis_kelamin }}
              </td>
              <td class="text-center" style="width: 100px;">
                {{ d.gedung }}
              </td>
              <td class="text-center" style="width: 100px;">
                {{ d.no_kamar }}
              </td>
              <td :class="'text-center ' + (d.karantina == 'Y'? 'text-negative ': '')" style="width: 100px;">
                {{ d.nilai_survey }}
              </td>
            </tr>
          </tbody>
          <tbody v-else class="bg-green-1">
            <tr>
              <td class="text-center text-grey" colspan="99">
                tidak ada data
              </td>
            </tr>
          </tbody>
        </q-markup-table>
      </div>
      <div class="row justify-end">
        <q-pagination
          v-model="activePage"
          :max="Math.ceil(count_survey/10)"
          input
          input-class="text-orange-10"
          @click="fetchData(orderBy)"
        />
      </div>
    </div>
  </q-page>
</template>

<style>
</style>

<script>
export default {
  name: 'Result',
  data(){
    return{
      listData: [],
      need_karantina: 0,
      need_isolasi: 0,
      count_siswa: 0,
      count_survey: 0,
      orderBy: '',
      orderType: '',
      activePage: 1,
      range_date: {},
      kode_unik: 'ARRCVD',
      search: '',
      confirm: false,
      count_karantina: 0,
    }
  },
  mounted(){
    this.fetchData('no_induk')
  },
  methods:{
    fetchData(order){
      // this.orderBy = order

      // if(this.orderType == 'ASC'){
      //   this.orderType = 'DESC'
      // }
      // else{
      //   this.orderType = 'ASC'
      // }
      this.orderBy = 'id'
      this.orderType = 'DESC'
      
      var src = '%25'
      if(this.search != '')
        src = this.search

      this.$http.get('/list_survey/' + src + '/' + this.orderBy + '/' + this.orderType + '/' + (this.activePage - 1) * 10, {})
        .then (result => {
          this.listData = result.data
          // this.count_survey = result.data.length
        })
      this.$http.get('/jml_siswa', {})
        .then (result => {
          this.count_siswa = result.data.count
        })
      this.$http.get('/jml_survey/'+src, {})
        .then (result => {
          this.count_survey = result.data.count
        })
      this.$http.get('/perlu_karantina', {})
        .then (result => {
          this.need_karantina = result.data.count
        })
      this.$http.get('/jml_karantina_archived/'+src, {})
        .then (result => {
          this.count_karantina = result.data.count
        })
      this.$http.get('/perlu_isolasi', {})
        .then (result => {
          this.need_isolasi = result.data.count
        })
      this.$http.get('/range_date', {})
        .then (result => {
          this.range_date = result.data
        })
        //Ga jadi dipake
      // this.$http.get('/kode_unik', {})
      //   .then (result => {
      //     this.kode_unik = result.data.kode_unik
      //   })
    },
    arsipkan(){
      this.$http.put('/arsipkan', {})
      .then (result => {
        //Ga jadi dipake
        // let rand = Math.random().toString(36).substring(6).toUpperCase();
        // this.$http.post('/token/' + rand, {})
        // .then (result => {
        // })
        
          this.fetchData('nama')
          this.confirm = false
      })

    }
  }
}
</script>

<style lang="scss" scoped>
.my-card{
  // min-width: 250px;
  flex-grow: 2;
  // background-color: #B9F6CA;
  background-color: #232979;
  color: #BABEF2;
}
.q-table--dense .q-table tbody tr, .q-table--dense .q-table tbody td {
  height: 36px;
}
</style>
